import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import DesignSystem from "../design-system/vue.js";

const app = createApp(App);

// Design System component registration
DesignSystem.register(app);

app
  .use(store)
  .use(router)
  .mount("#app");
