<template>
  <List el="xsm p-lg" id="home">
    <List el="xsm">
      <Text el="h2" class="block">Our Date Ideas</Text>
      <Text class="block">After getting to know you, I have made a list of <b>48 date ideas</b> that I think we would enjoy together.</Text>
      <Text class="block">Please rate them from <b>1-5 stars</b> (<i>lower stars = less interested</i>)</Text>
    </List>
    <div class="btn">
      <Btn @click="to('/dates/rate')">Start Rating</Btn>
    </div>
  </List>
</template>

<script setup>
  import {onMounted} from 'vue'
  import router from "@/router";
  import gsap from 'gsap'

  const to = (path)=>{
    router.push(path);
  }

  const startAnimation = async ()=>{
        let tl = gsap.timeline({delay:0.5});
        tl.fromTo('#home',{opacity:0, y:-50, scale:0}, {opacity:1, y:0, scale:1,duration:2.5, ease: "elastic.out(1, .8)"});
        tl.to('.block',{opacity:1,stagger:0.8, duration:1, ease: "sine.out"}, "-=2");
        tl.fromTo('.btn',{opacity:0, scale:0.5, y:50}, {opacity:1, y:0,scale:1,duration:2, ease: "elastic.out(1, 0.5)"});
        // tl.to('.star:not(.after)',{scale:1,stagger:0.1, duration:1, delay:0.2, ease: "elastic.out(1, 0.3)"}, "<");
  }

  onMounted(()=>{
        startAnimation();
    })

</script>
<style lang="scss" scoped>
[el^="header"] {
  border-radius: 0 0 0 8px;
  --bg-color: #f3f3f3;
}
[el^="section"] {
  border-left: 1px solid #f1f1f1;
}
// [el^="footer"] {
//   --bg-color: green;
// }
#home{
  max-width: 500px;
  background-color: hsl(240deg 100% 90% / 95%);
  border-radius: 24px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 7%);
  position: fixed;
    top: 100px;
    width: calc(100% - 16px);
    left: 50%;
    transform: translateX(-50%);
    opacity:0;
}
.block,.btn{
  opacity:0;
}
</style>
