<template>
  <div :el="mods" v-if="active">
    <List el="md rnd3">
      <List el="column-between">
        <Text el="h3" v-if="title">{{ title }}</Text>
        <span el="icon" class="material-icons" @click="toggle">✖</span>
      </List>
      <slot></slot>
    </List>
  </div>
</template>

<script>
import { check } from "./verify";
import List from "./List.vue";
import Text from "./Text.vue";
const name = "modal";
export default {
  components: {
    List,
    Text,
  },
  props: {
    el: {
      type: String,
      default: "center",
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    toggle() {
      this.active = !this.active;
    },
  },
  watch: {
    active(val) {
      if (val) {
        document.querySelector("body").classList.add("stop-scrolling");
      } else {
        document.querySelector("body").classList.remove("stop-scrolling");
      }
    },
  },
  computed: {
    mods() {
      return this.el ? `${name}-${this.el}` : name;
    },
  },
  mounted() {
    check(name, this.el);
  },
};
</script>
